.filterpannel {
	height: 50px;
	width: 100%;
	font-size: 20px;
	padding: 10px;
	background: var(--filtering);
}

.filter-wrap {
	display: flex;
	flex-direction: row;
	grid-gap: 5px;
}

.filter-wrap input::after {
	content: 'yo';
}

.filtername {
	font-size: 20px;
	margin: auto;
	margin-left: 10px;
	font-weight: bold;
	text-align: right;
}
.nofilter {
	/*margin: 9px 0;*/
}

