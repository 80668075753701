.sticky-header {
	width: 100%;
	background-color: inherit;
	transition: top 0.5s;
}

.sticky-header[datas="false"]{	
	/*top: 40vh;
	transition: top 0.5s;
	*/
}
