html {
	/*transition: filter 0.5s ease;*/
}

body, #root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-family: Arial;
  background-color: var(--main-color-light);
  color: var(--dark-color);
  padding-bottom: 50px; 
  /*transition: filter 0.5s;*/
}

button {
	border: 1px outset !important;
}

button:active {
	border: 1px inset !important;
}

body {
	overflow-x: hidden;
}

li::marker {
	content: '';
}

:root {
	--main-color: #8093F1;
	--main-color-darker: #6C00F9;
	--main-color-non-opaque: #ebeefd;
	--main-color-light: white;
	--dimension: #72DDF7;
	--numeric: #B388EB;
	--filtering:  #ebeefd;
	--basic-btn: #E6EBF4;
	--light-color: white;
	--dark-color: #000000d6;
	--transparent-color: #0000000d;
	--opaque-light-color: #e4e7f5a3;
}

