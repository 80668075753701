.darkmode {
	filter: invert(1) hue-rotate(180deg) ;
}

/*to always keep as it is*/
.darkmode img:not(.icon), .invert-video-color {
	filter: invert(1) hue-rotate(180deg) ;
}

.light {

}

#darkactivated {
	display: none;
}

[for="darkactivated"] {
	align-items: center;
	border-radius: 500px;
	display: flex;
	width: 30px;
	justify-content: center;
	margin-right: 20px;
	/*border-style: inset;*/
	/*padding: 10px;*/
}

[for="darkactivated"]:before {
	color: var(--dark-color)
}
