.wrap-url, .wrap-url h1 {	
	margin: 0;
	margin-top: 10px;
}

/*never let the eraser go to next line*/
.wrap-url > div:nth-child(1) {
	margin: 0 30px;
	white-space: nowrap;
	display: flex;
}

.url-input {
	background-color: var(--main-color-non-opaque);
	padding: 15px !important;
	font-size: 18px;
	width: 100%;
	text-align: left;
	outline: none;
}

.btn-url {	
	border-radius: 150px;
	padding: 5px;
	margin: 2px;
	background-color: var(--basic-btn);
	font-size: 15px;
	font-weight: bold;
}


.go-wrapper {
	margin: 0 auto;
	max-width: 94%;
}

.go {
	font-size: calc(14px + 1vw);
	padding: 10px;
	margin-top: 5px;
	cursor: pointer;
	height: auto;
	color: var(--dark-color);
	background-position: 0 -100%;
	font-weight: bold;
	background-color: var(--numeric);
	
}

.go-content {	
	display: inline-block;
  	position: relative;
}

.go-content:after {
  content: '';
  position: absolute;
  width: 100%;
  text-align: center;
  transform: scaleX(0);
  height: 4px;
  bottom: -4px;
  left: 0;
  background-color: var(--dark-color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.go-content:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.docs {
	background-color: var(--basic-btn);
}

.data-src-wrapper {
	text-align: center;
	max-width: 90vw;
	margin-top: 5vh;
	margin-left: auto;
	margin-right: auto;
	padding: 0;
}

.remember {
	margin-bottom: 20px;
}


.data-src-wrapper:active, .data-src-wrapper:hover {
  opacity: 100%;
}

.chooseAPI {
	margin: 5px 0;
	padding: 0 5px;
	cursor: pointer;
	color: grey;
}

.chooseAPI:hover {
	text-decoration: underline;
}

.apilist {
	text-align: left;
	border-radius: 15px;
	background-color: var(--main-color-non-opaque);
	overflow-wrap: anywhere;
	width: 300px;
	margin: 0 auto;
	padding: 0;
	margin-bottom: 15px;
	max-height: 400px;
	overflow-y: auto;
}

.apilist li {
	padding: 15px;
	opacity: 0.6;
	cursor: pointer;
}

.apilist li:hover {
	opacity: 1;
}

.close-api-list {
	z-index: 5;
	display: inline-grid;
	position: sticky;
	width: 100%;
	top: 0;
	cursor: pointer;

}

.close-api-list span {
	padding: 5px 7px;
	position: absolute;
	border-radius: 50px;
	background-color: #00000078;
	font-size: 18px;
	height: 20px;
	font-weight: bolder;
	right: 0;
	color: white;
}

.personal {
  color: var(--main-color-darker);
}

.addnewurl {
	padding: 10px;
	display: inline-block;
	max-width: fit-content;
}

.formURL {
	border-radius: 15px;
	background-color: #0000000a;
	padding: 0 25px 25px 25px;
	margin: 0 auto;
	width: 200px;
}

.newurl {
	padding: 5px;
	margin: 5px;
}