.panel {
	min-width: 350px;
    max-width: 95vw;
    margin: auto;
    width: 100vw;
	height: 80vh;
	min-height: 400px;
	display: flex;
	grid-gap: 20px;
    /*margin-top: 140px !important;*/
    margin-bottom: 75px !important;
}


@media screen and (max-width: 500px) {
    .panel {
        display: block;
    }
}

.animate {    
    animation: fadein 1s;
    -moz-animation: fadein 1s; /* Firefox */
    -webkit-animation: fadein 1s; /* Safari and Chrome */
    -o-animation: fadein 1s; /* Opera */
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}



