.charts {
	height: 100%;
	position: relative;
	top: 15px;
}

.square {
	border-radius: 15px;
	width: 100%;
	height: 80vh;
	min-width: 320px;
	margin: auto;
}

.viz {
  color: var(--dark-color);
  /*height: 100%;*/
  height: inherit;
}

@media screen and (max-width: 500px) {
	.viz {
		max-width: 100%;
	}
}


.table-wrap {
	overflow-y: auto;
	/*height: 100%;*/
	/*max-height: 75vh;*/
	height: 75%;
	max-height: 60vh;
	position: relative;
	top: 10px;
}

.json-to-table {
	margin-left: auto;
	margin-right: auto;
	margin-top: 15px;
	margin-bottom: 50px;
	max-height: 70%;
	overflow-y: scroll;
}

/*Field names*/
.json-to-table-head {
	font-weight: bold;
	font-size: 18px;
	position: sticky;
	top: -3px;
	width: calc(100% - 1px);
	background-color: var(--opaque-light-color);
	z-index: 100;
	backdrop-filter: blur(5px);
	overflow-wrap: break-word;
	cursor: pointer;
}

.json-to-table-head .default:before {
	content: '';
}

.json-to-table-head .up:before {
	content: ' ▲ ';
}

.json-to-table-head .down:before {
	content: ' ▼ ';
}

.json-to-table tr {
  background-color: #00000008 !important;
}

.json-to-table th, .json-to-table td {
	padding: 10px;
}

.json-to-table td {
	vertical-align: top;
}

.simplejson {
	max-height: 75vh;
	text-align: left;
	margin: 0 auto;
	width: 100%;
}

.simplejson  div:nth-child(1) {
	text-align: center;
    position: sticky;
    top: 0;
    background: #ebeefde0;
}

.jsoncontent {
	margin: 0 auto;
	margin-bottom: 50px;
	min-width: 320px;
	width: fit-content;
}

.dataviz a, .dataviz a:visited {
	color: #06c;
}

.dataviz a[target="_blank"]::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}


.quote {
	margin: 0;
	font-size: small;
	font-style: italic;
	color: grey;
	text-align: left;
	max-width: 100vw;
	overflow-wrap: anywhere;
	height: fit-content;
	text-align: center;
}

.quote label {
	text-decoration: underline;
}

.remark {
  display: block;
  padding: 10px;
  color: #8080808f;
}

.pagination {
	bottom: -50px;
	position: relative;
	background-color: #ebeefda3;
}

.pagination h3 {
  margin: 0 auto;
}

.page {
	cursor: pointer;
	z-index: 3;
	position: relative;

}

.activepage {
	cursor: initial;
	color: var(--dark-color) !important;
	text-decoration: none !important;
	font-weight: bold;
}

.page:hover {
	text-decoration: underline;
}

.imgtable {
	max-width: 150px;
	border-radius: 15px;
}