strong {
	font-weight: bold;
}

.tooltip {
    width: 100%;
	line-height: 1.5;
	margin: 5px;
	padding: 5px 0;
	border-radius: 15px;
}

.aggregator {
	text-align: center;
	display: inline-block;
	padding: 5px;
	margin-bottom: 5px;
}

.tooltip h3 {
	margin: 0;
	line-height: 2;
	font-weight: lighter;
	color: var(--dark-color);
}

.highlight {
	padding: 0 10px;
	border-radius: 15px;
	margin: 0px;
	display: inline-block;
	width: max-content;
}

.tooltipfield {
	/*display: flex;*/
}

.fieldbtn {
	color: var(--light-color);
	display: inline;
	font-size: 18px;
	cursor: pointer;
	border-radius: 20px;
	padding: 0 5px;
}

.leftbtn {
	margin-right: 15px;
}

.rightbtn {	
	margin-left: 15px;
}

.fieldbtn:hover {
	background-color: var(--transparent-color);
}

.remover:after {
	content: 'x';
}

.orderby {
	font-weight: bolder;
}

.titleVizWrap {
	max-width: 100%;
	display: flex;
	flex-direction: row;
	font-size: 12px;

	padding: 5px;
	border-radius: 15px;
	background: var(--transparent-color);
}

.filtervalue {
	background-color: var(--filtering)
}

.important {
	font-weight: bold;
}