.dataviz {
	/*overflow-wrap: anywhere;*/
	overflow-y: hidden;
	padding: 10px;
	padding-bottom: 50px;
	border-radius: 15px;
	max-width: 100%;
	overflow: hidden;
	background-color: var(--main-color-non-opaque);
}
.dataviz-wrap {
	min-height: 100%;
	display: grid;
	grid-gap: 5px;
	grid-auto-flow: row;
	margin: 0 0.25vw;
	grid-template-rows: min-content auto;
}
.vizwrap {
	height: 90%;
	/*max-height: 65vh;*/ /*TODO : put this as height when downloading*/
	max-width: 100%;
}

.echarts-for-react {
	height: 100% !important;
	min-height: 250px;
}

.viz-btn-wrap {
	grid-auto-flow: row;
	text-align: end;
}

.viz-btn-wrap button {
	min-height: 30px;
	padding: 5px;
	margin-top: 5px;
}

.viz-btn-wrap .jsonfield {

	border-color: var(--main-color);
	border-width: 1px;
	background-color: var(--light-color);
	color: var(--dark-color);
	font-weight: bolder;
	margin: auto 0;
	height: min-content;
}

.viz-btn-right-wrap {
	display: flex;
}

.viz-topmenu {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: auto 30px;
}

.more-btns {
	cursor: pointer;
	display: grid;
	z-index: 9999;
	height: min-content;
	width: min-content;
	border-radius: 50px;
}

.more-btns:after {
  content: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="50" height="50" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath fill="currentColor" d="M12 10c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2zm0 12c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2z"%2F%3E%3C%2Fsvg%3E');
}

.more-btns-wrap {	
	position: absolute;
	margin-top: 40px;
	right: 40px;
  	padding: 0;
  	background: var(--light-color);
  	color: var(--dark-color);
  	border-radius: 5px;
  	text-align: left;
	font-size: 17px;
	list-style: none;
}

.more-btns-wrap li {
	opacity: 0.5;
	padding: 15px;
}

.more-btns-wrap li:hover {
	opacity: 1;
}

.more-btns-wrap li:before {
	position: relative;
	top: 7px;
	margin-right: 10px;
	display: inline-flex;
}

.reinit {	
	height: min-content;
	padding: 5px;
	padding-top: 8px;
}

.reinit:before {
	content: /*''; /**/url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"%3E%3Cpath fill="black" d="M18 3H8.446c-.44 0-1.071.236-1.402.525L.248 9.473a.682.682 0 0 0 0 1.053l6.796 5.947c.331.289.962.527 1.402.527H18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2.809 11l-2.557-2.557L10.078 14l-1.443-1.443L11.191 10L8.635 7.443L10.078 6l2.557 2.555L15.19 6l1.444 1.443L14.078 10l2.557 2.555L15.191 14z"%2F%3E%3C%2Fsvg%3E');
}


.save:before {
	content: /*''; /**/url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"%3E%3Cpath fill="black" d="M5 2v2.5A1.5 1.5 0 0 0 6.5 6h2A1.5 1.5 0 0 0 10 4.5V2h.379a2 2 0 0 1 1.414.586l1.621 1.621A2 2 0 0 1 14 5.621V12a2 2 0 0 1-2 2V9.5A1.5 1.5 0 0 0 10.5 8h-5C4.673 8 4 8.669 4 9.498V14a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h1Zm1 0v2.5a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5V2H6ZM5 14h6V9.5a.5.5 0 0 0-.5-.5h-5c-.277 0-.5.223-.5.498V14Z"%2F%3E%3C%2Fsvg%3E');
}

.download:before {
	content: /*''; /**/url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="30" height="30"  preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15V3m0 12l-4-4m4 4l4-4M2 17l.621 2.485A2 2 0 0 0 4.561 21h14.878a2 2 0 0 0 1.94-1.515L22 17"%2F%3E%3C%2Fsvg%3E');
}

.embed:before {
	content: /*''; /**/url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="30" height="30"  preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 16"%3E%3Cpath fill="black" d="m13 11.5l1.5 1.5l5-5l-5-5L13 4.5L16.5 8zm-6-7L5.5 3l-5 5l5 5L7 11.5L3.5 8zm3.958-2.148l1.085.296l-3 11l-1.085-.296l3-11z"%2F%3E%3C%2Fsvg%3E');
}

/*
.save, .embed {
	margin: auto 0;
	display: grid;
	grid-auto-flow: row;

}*/

.charttype-wrap {
	width: 51px;
	height: 53px;
	padding: 0;
}

.listcharttype {
	margin: 0; 
	padding: 0;
	display: inline-flex;
}

[name="charttype"]{
	display: none;
}

.listcharttype li {
  min-width: 30px;
}

.charttype-wrap input:not([chosen="true"]) ~ * {
	display: none;
}

.icon {
	cursor: pointer;
}

.img_field-wrap {
	text-align:center;
	margin:0;
	padding:0;
	display: flex;
}

.img_field {
	max-width: 250px;
}
