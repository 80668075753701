.top-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff; /* Adjust as needed */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: adds shadow */
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999; /* Ensure it's above other content */
}

.top-btns {
	display: flex;
}


.logo {
	position: relative;
	cursor: pointer;
	background-color: var(--basic-btn);
	margin-top: 15px;
	padding: 8px;
	border-radius: 15px;
}


.logo span:nth-child(1){
	color: var(--main-color)
}

.logo span:nth-child(2){
	color: var(--numeric);
}


.jsonviz-logo-wrap {
    margin-right: auto; /* Pushes nav to the right */
}


nav ol {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

nav li {
    padding: 15px 25px;
    border-radius: 10px 10px 0 0; /* no radius at the bottom let and right */
    cursor: pointer;
    color: var(--main-color);
    filter: grayscale(1);
}

nav li:hover {
  
}

.activenav {
  font-weight: bold;
  filter: grayscale(0) !important;
  border-bottom-style: solid;
}