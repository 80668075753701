.sidebar {
	overflow-y: auto;
	padding: 5px;
	min-width: 200px;
	height: 95vh;
	min-height: 470px;
	max-width: 300px;
	margin-top: 150px;
}

.reload:before {
	content: '⟳';
	font-size: 15px;
}

label[for="toggleSidebar"]{
	font-size: 5vh;
	display: none;
}
label[for="toggleSidebar"]::after {
	content: '☰';
}


.hidden-input {
	display: none;
}

@media screen and (max-width: 500px) {
	.sidebar {
		display: none;
		max-width: 100vw;
		margin-top: 0px;
	}

	label[for="toggleSidebar"] {
		display: flex;
	}

	#toggleSidebar:checked ~ .sidebar {
		display: block;
		margin-bottom: 100px;
	}

	#toggleSidebar:checked ~ label[for="toggleSidebar"]::after {
		content: 'x';
	}

}

.sidebtns button {
	margin: 0 auto;
	height: min-content;
	padding: 5px;
}
