.lds-spinner {
  color: official;
  position: fixed;
  width: 80px;
  height: 80px;
  top: calc(50vh - 40px);
  left: calc(50vw - 40px);
}

.loader {
  z-index: 99999;
  border-radius: 50%;
  border-top: 16px solid var(--main-color);
  border-right: 16px solid var(--numeric);
  border-bottom: 16px solid var(--basic-btn);
  border-left: 16px solid var(--dimension);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}