#root  {
  text-align: center;
  font-size: 15px;
}

#topping {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: none;
}

input, button, select, textarea {
	border-radius: 15px;
	font-size: 15px;
}

button {
	cursor: pointer;
}

section, h1 {
	/*margin: 0 !important;*/
}

.main-app {
	position: relative;
	top: 30px;
}

.url-notice {
	color: red;
	margin: 5px;
	display: block;
}

.nodecoration, .nodecoration:visited{
	text-decoration: none;
	/*color: inherit;*/
}

.jsonviz-logo-wrap {
	width: min-content;
}

.text-wrap {
	/*
	top: 15px;
	position: relative;
    margin-bottom: 15px;
    */
}


.section-text-wrap {
	text-align: center;
	width: 100vw;
	display: block;
	transition: display 0.5s;
	padding-bottom: 20px;
	border-radius: 20px;
}

.section-text-wrap button {
	padding: 7px;
	font-size: calc(10px + 1vw);
}

.section-text-wrap[withURL="true"] {
	/*display: none;*/
}

.video-wrap video {
	min-width: 250px;
	max-width: 40vw;
	max-height: 30vh;
	border-radius: 15px;
	margin: 0;
}

.demo-content {
	display: block;
}

.logo-text {
	padding: 0;
	max-width: 80%;
	position: relative;
	color: var(--main-color);
	/*font-size: calc(20px + 2vw);*/
	font-size: calc(45px + 2vw);
	margin: auto;
	display: inline-table;
}
.logo-text strong {
	border-bottom: 10px var(--numeric) solid;
	display: inline-block;
	font-weight: inherit;
}

.onepage {
	height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-in-page {
    flex-direction: column;
}

.bottomRef {
	height: 90vh;
}

.smallBottomRef {
	height: 50px;
}

@media screen and (max-width: 500px) {

	.smallBottomRef {
		height: 150px; /*GREATER change to have the right margin of panel*/
	}

}
