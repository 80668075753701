.groupfield {
	width: auto;
	min-width: 150px;
	border: 0.05px  var(--dark-color) inset;
	border-top: none;
	border-radius: 15px;
	margin-bottom: 15px;
	color:  var(--light-color);
}
.groupfield h2 {
	padding: 10px;
	margin-top: 0;
	margin-bottom: 0;
	border-radius: 15px 15px 0 0;

	
}

.groupfield h2 {
	background-color: #90e5ff;
}

.groupfield li * {
	display: inline-flex;
}

.groupfield button {	
  /*height: min-content;*/
  height: 32px;
  padding: 5px;
  margin-left: 2px;
  font-size: 20px;
}

/*Dimensions, links, images, ...*/
.groupfield h2, .groupfield .jsonfield, .dim {
	background-color:  var(--dimension);
}

/*Dates*/
#group0.groupfield h2, #group0.groupfield .jsonfield {
	background-color: #ff8a8a; 
}

/*Nombre*/
#group4.groupfield h2, #group4.groupfield .jsonfield, .num {
	background-color: var(--numeric);
}

/*Calc (todo)*/
#Calculs.groupfield h2, #Calculs.groupfield .jsonfield {
	background-color: #36a83d;
}

#Histogrammes.groupfield h2, #Histogrammes.groupfield .jsonfield {
	background-color: #ff8e16;
}
