.jsonfield {
	padding: 5px;
	border: 3px outset;
	margin: 5px !important;
	border-radius: 10px;
	cursor: pointer;
	color: var(--dark-color);
	height: auto;
	overflow-wrap: anywhere;
}

.jsonfield-wrap {
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 0;
}

.btn-jsonfield{
	border: 0px outset solid grey;
	position: relative;
	right: 0; 
}

.jsonfield:active, .jsonfield[chosen="true"] {
	border-style: inset;

}

.btn-jsonfield-filter:before {
	content: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"%3E%3Cpath fill="currentColor" d="M8 11v1.12a.5.5 0 0 0 .15.35l7.28 7.36a.5.5 0 0 1 .15.35v6.89a.5.5 0 0 0 .28.45l3.95 1.41a.5.5 0 0 0 .72-.45v-8.39a.54.54 0 0 1 .18-.35l7.12-7.25a.5.5 0 0 0 .15-.35V11Z" class="clr-i-solid clr-i-solid-path-1"%2F%3E%3Cpath fill="none" d="M0 0h36v36H0z"%2F%3E%3C%2Fsvg%3E')
}

.btn-jsonfield-gear[configmode="true"]:before {
	content: '✔'
}

.btn-jsonfield-gear:not([configmode="true"]):before {
	content: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"%3E%3Cpath fill="currentColor" d="M764.416 254.72a351.68 351.68 0 0 1 86.336 149.184H960v192.064H850.752a351.68 351.68 0 0 1-86.336 149.312l54.72 94.72l-166.272 96l-54.592-94.72a352.64 352.64 0 0 1-172.48 0L371.136 936l-166.272-96l54.72-94.72a351.68 351.68 0 0 1-86.336-149.312H64v-192h109.248a351.68 351.68 0 0 1 86.336-149.312L204.8 160l166.208-96h.192l54.656 94.592a352.64 352.64 0 0 1 172.48 0L652.8 64h.128L819.2 160l-54.72 94.72zM704 499.968a192 192 0 1 0-384 0a192 192 0 0 0 384 0z"%2F%3E%3C%2Fsvg%3E')
}


.config-wrap {
	padding: 5px;
	display: flex;
}

.setup-num {
	font-weight: initial;
	color: var(--dark-color);
	margin: 0;
}
